<template>
  <ShareLandingComV2
    ref="ShareLandingCom"
    v-if="musicInfo.cdn_url"
    :mediaInfo="mediaInfo"
    @changeAudioPlay="changeAudioPlay"
    :musicOrVideoShow="true"
  >
    <template slot="control">
      <MusicControlV2
        :changeAudioPlay="changeAudioPlay"
        :isAudioPlay="isAudioPlay"
        v-if="MusicContentAb === 'b'"
        :openApp="openApp"
      />
      <MusicControlV3
        v-if="MusicContentAb === 'd' || MusicContentAb === 'c'"
        :handleModelV2="handleModelV2"
        :changeAudioPlay="changeAudioPlay"
        :isAudioPlay="isAudioPlay"
        :openApp="openApp"
      />
      <MusicControlV1
        v-if="MusicContentAb === 'a'"
        :changeAudioPlay="changeAudioPlay"
        :isAudioPlay="isAudioPlay"
        :openApp="openApp"
      />
      <!--    用于自动下载，复制邀请码-->
      <div
        ref="autoDownload"
        id="auto-download"
        @click="openApp('', '#auto-download')"
        :data-clipboard-text="mediaInfo.invite_code"
      ></div>
      <!--    用于点击空白处，复制邀请码-->
      <div ref="copyCode" @click="copyCode" id="copy-code" :data-clipboard-text="UmLink.clipboardInfo"></div>
    </template>
  </ShareLandingComV2>
</template>

<script>
  import ShareLandingComV2 from './components/ShareLandingComV2';
  import MusicControlV2 from '@/views/Welfare/Fission/ContentLanding/components/MusicControl-v2';
  import MusicControlV3 from '@/views/Welfare/Fission/ContentLanding/components/MusicControl-v3';

  import MusicControlV1 from '@/views/Welfare/Fission/ContentLanding/components/MusicControl-v1';
  import { mapGetters, mapState, mapMutations } from 'vuex';
  import Clipboard from 'clipboard';

  export default {
    components: { ShareLandingComV2, MusicControlV2, MusicControlV1, MusicControlV3 },
    data() {
      return {
        isAudioPlay: false,
      };
    },
    computed: {
      ...mapState('share/contentLanding', ['musicInfo', 'mediaInfo']),
      ...mapGetters('share/contentLanding', [
        'MusicContentAb',
        'OriginLyricsList',
        'UmLink',
        'PageTitle',
        'QuickAppInfo',
      ]),
    },

    async asyncData({ store, $http, params }) {
      const data = await $http.post('/share/music_v3', {
        ...params,
      });
      const {
        user: { invite_code, uid, avatar_small },
        hot_music_list,
        hot_video_list,
        music: {
          music_info: {
            title,
            cdn_url,
            cover,
            is_valid,
            not_valid_txt,
            show_likes,
            comments,
            tags,
            background,
            has_lrc,
            lrc_url,
          },
          music_user: { nickname, avatar_url },
          video_info: { play_url, video_cover },
        },
        is_download,
        has_ad,
        marquee,
        music_content_ab,
        quick_app,
      } = data;
      const musicInfo = {
        title,
        cdn_url,
        nickname,
        avatar_url,
        cover,
        is_valid,
        not_valid_txt,
        video_cover,
        play_url,
        avatar_small,
        show_likes,
        comments,
        tags,
        background,
        has_lrc,
        lrc_url,
      };
      const mediaInfo = {
        invite_code: invite_code && `邀请码【${invite_code}】`,
        uid,
        hot_music_list,
        hot_video_list,
        is_download,
        has_ad,
        marquee,
      };
      let page_title;
      switch (music_content_ab) {
        case 'a':
          page_title = '内容分享落地页';
          break;
        case 'b':
          page_title = '内容落地页迭代';
          break;
        case 'c':
          page_title = '内容落地页3';
          break;
        case 'd':
          page_title = '内容落地页4';
          break;
        default:
          page_title = '内容分享落地页';
          break;
      }
      store.commit('share/contentLanding/SET_PAGE_TITLE', page_title);
      store.commit('share/contentLanding/SET_MUSIC_INFO', musicInfo);
      store.commit('share/contentLanding/SET_MEDIA_INFO', mediaInfo);
      store.commit('share/contentLanding/SET_MUSIC_CONTENT_AB', music_content_ab);
      store.commit('share/contentLanding/SET_QUICK_APP_INFO', quick_app);

      // 获取歌词
      if (has_lrc && lrc_url) {
        const lyricsData = await $http.get(lrc_url);
        store.commit('share/contentLanding/SET_ORIGIN_LYRICS_LIST', lyricsData.data);
      }
    },

    mounted() {
      if (this.musicInfo.has_lrc && this.musicInfo.lrc_url) {
        this.initPoem();
      }
      window.addEventListener('click', this.clickCallBack);
      this.$on('hook:beforeDestroy', () => {
        window.removeEventListener('click', this.clickCallBack);
      });
      const sensorsData = JSON.parse(this.getCookie('sensorsdata2015jssdkcross'));
      this.$http.TaskPost('/ShareMusicReward/Report', {
        music_code: this.$route.query.music_code,
        share_from: this.$route.query.share_from,
        distinct_id: sensorsData.distinct_id,
      });
      try {
        // 判断跳转快应用
        let { is_quick_app, business, quick_app_url, package: packageName } = this.QuickAppInfo;
        if (window.channelReady && is_quick_app && quick_app_url) {
          window.channelReady((bAvailable) => {
            if (window.appRouter && bAvailable) {
              let query = { businessIndex: business === 'music' ? 0 : 1, ...this.$route.query };
              window.appRouter(packageName, quick_app_url, query);
            }
          });
        }
      } catch (e) {
        console.log(e);
      }
    },

    methods: {
      ...mapMutations('share/contentLanding', ['SET_LYRICS_LIST']),
      getCookie(name) {
        let arr,
          reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)');
        if ((arr = document.cookie.match(reg))) return unescape(arr[2]);
        else return null;
      },
      /**
       * @description 点击空白回调
       */

      clickCallBack(e) {
        if (!e.target.id) {
          this.$track({
            page_title: this.PageTitle,
            element_name: '空白点击',
          });
        }
        this.$refs.copyCode.click();
      },
      /**
       * @description 点击空白处复制邀请码
       */
      copyCode() {
        new Clipboard('#copy-code');
      },
      handleModelV2(flag, element_name, remarks) {
        this.$track({
          page_title: this.PageTitle,
          element_name,
          remarks,
        });
        this.$refs.ShareLandingCom.handleModelV2(flag);
      },
      async initPoem() {
        try {
          // 解析歌词
          if (this.OriginLyricsList) {
            const LyricsList = this.OriginLyricsList.split('\n');
            const regTime = /\[\d{2}:\d{2}.\d{2,3}/;
            const lyricsObjArr = [];

            LyricsList.forEach((item) => {
              if (item === '') return;
              const obj = {};
              const time = item.match(regTime);

              obj.lyric = item.split(']')[1].trim() === '' ? '' : item.split(']')[1].trim();
              obj.time = time ? this.formatLyricTime(time[0].slice(1, time[0].length - 1)) : 0;
              if (obj.lyric === '') {
                console.log('这一行没有歌词');
              } else {
                lyricsObjArr.push(obj);
              }
            });
            this.SET_LYRICS_LIST(lyricsObjArr);
          }
        } catch (e) {
          console.log('歌词解析出错~');
        }
      },
      formatLyricTime(time) {
        // 格式化歌词的时间 转换成 sss:ms
        const regMin = /.*:/;
        const regSec = /:.*\./;
        const regMs = /\./;

        const min = parseInt(time.match(regMin)[0].slice(0, 2));
        let sec = parseInt(time.match(regSec)[0].slice(1, 3));
        const ms = time.slice(time.match(regMs).index + 1, time.match(regMs).index + 3);
        if (min !== 0) {
          sec += min * 60;
        }
        return Number(sec + '.' + ms);
      },
      /**
       * @description @改变音频状态
       * @data @Boolean
       * @return @void
       */

      changeAudioPlay(arg) {
        if (this.MusicContentAb === 'a') {
          if (this.musicInfo.is_valid === '1') {
            this.$toast(this.musicInfo.not_valid_txt);
          } else {
            this.isAudioPlay = arg;
            let audio = document.getElementById('music_dom');
            if (arg) {
              audio.paused && this.$track({ page_title: '内容分享落地页', element_name: '播放歌曲' });
              audio.play();
            } else {
              audio.pause();
            }
          }
        } else {
          let isNeedReport = (arg.target && !arg.target.id) || arg === true || arg.target.id === 'video_dom';
          if (!this.musicInfo.play_url) {
            let music_dom = document.getElementById('music_dom');
            if (music_dom.paused) {
              if (isNeedReport) {
                this.$track({ page_title: this.PageTitle, element_name: '播放' });
              }
              music_dom.play().then(() => {
                this.isAudioPlay = true;
              });
            } else {
              if (isNeedReport) {
                this.$track({ page_title: this.PageTitle, element_name: '暂停' });
              }
              music_dom.pause();
              this.isAudioPlay = false;
            }
          } else {
            let video_dom = document.getElementById('video_dom');
            if (video_dom.paused) {
              if (isNeedReport) {
                this.$track({ page_title: this.PageTitle, element_name: '播放' });
              }
              video_dom.play().then(() => {
                this.isAudioPlay = true;
              });
            } else {
              if (arg === true) return;
              if (isNeedReport) {
                this.$track({ page_title: this.PageTitle, element_name: '暂停' });
              }
              video_dom.pause();
              this.isAudioPlay = false;
            }
          }
        }
      },
      /**
       * @description 下载app
       */

      openApp(element_name, element_id, remarks) {
        this.$refs.ShareLandingCom.openApp(element_name, element_id, remarks);
      },
    },
  };
</script>
