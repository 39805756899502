<template>
  <div>
    <div class="share-landing-com-container">
      <!-- 顶部置顶组件 -->
      <div class="share-landing-com-top-warp-bg"></div>
      <div class="share-landing-com-top-warp">
        <img class="top-warp-img__logo" src="@/assets/images/share-content/logo.png" alt="快音" />
        <div class="top-warp-content">
          <div class="top-warp-logo-name">
            {{ MusicContentAb === 'd' || MusicContentAb === 'c' ? '你的好友' : '快音' }}
          </div>
          <div class="top-warp-invite-info">邀请你来<span class="name">快音</span>听歌看视频</div>
        </div>
        <div
          id="com-button-open-app"
          :data-clipboard-text="mediaInfo.invite_code"
          @click="openApp('打开App', '#com-button-open-app')"
          class="top-warp-open-btn"
        >
          打开App
        </div>
      </div>
      <!-- 顶部置顶组件  结束-->
      <div class="bg_content">
        <!-- 控件插槽位置 -->
        <slot name="control"></slot>
        <!-- 相似歌曲、推荐视频组件 -->
        <SameAndWonderful
          :hot-music-list="mediaInfo.hot_music_list"
          :hot-video-list="mediaInfo.hot_video_list"
          :musicOrVideoShow="musicOrVideoShow"
          :mediaInfo="mediaInfo"
          :openApp="openApp"
          @playRecommendMusic="listenRecommendedMusic"
          @playRecommendVideo="seeRecommendedVideo"
          @playMusicOrVideo="playMusicOrVideo"
        ></SameAndWonderful>
      </div>
    </div>
    <!-- 欢迎弹窗组件 -->
    <WelcomeModel
      v-show="welcomeModelShow"
      :inviteCode="mediaInfo.invite_code"
      :openApp="openApp"
      :closeModel="closeModel"
    ></WelcomeModel>
    <WelcomeModelV2
      v-show="welcomeModelV2Show && MusicContentAb === 'd'"
      :inviteCode="mediaInfo.invite_code"
      :openApp="openApp"
      :handleModelV2="handleModelV2"
    ></WelcomeModelV2>
  </div>
</template>

<script>
  import SameAndWonderful from './SameAndWonderful';
  import Clipboard from 'clipboard';
  import WelcomeModel from './Modal';
  import WelcomeModelV2 from './Modal-v2';

  import Download from '@/utils/download';
  import InitUmLink from '@/utils/init-ulink';
  import { mapGetters, mapMutations } from 'vuex';

  export default {
    components: { SameAndWonderful, WelcomeModel, WelcomeModelV2 },
    props: {
      musicOrVideoShow: {
        // true 显示音乐 ; false 显示视频播放
        type: Boolean,
        default: true,
      },
      mediaInfo: {
        type: Object,
        default: () => {},
      },
    },
    computed: {
      ...mapGetters(['traceId']),
      ...mapGetters('share/contentLanding', ['MusicContentAb', 'PageTitle', 'UmLink']),
    },
    data() {
      return {
        userHandleTimer: null, // 弹窗定时器
        welcomeModelShow: false, // 是否有欢迎弹窗
        welcomeModelV2Show: false,
        isVideoPlay: false, // 是否播放视
        clipboard: null,
        modalOpenTime: 15000, // 弹窗打开时间
      };
    },
    destroyed() {
      document.removeEventListener('click', this.setTimeoutFun, false);
    },
    beforeMount() {
      this.fissionTrace();
    },
    mounted() {
      let { immediate } = this.$route.query;
      immediate && (this.welcomeModelShow = true);

      // 监听页面各类点击事件 设置弹窗倒计时
      document.addEventListener('click', this.setTimeoutFun, false);

      // 初始化友盟
      this.initULink();
    },
    methods: {
      ...mapMutations('share/contentLanding', ['SET_UM_LINK']),
      /**
       * @description: 裂变溯源
       */

      fissionTrace() {
        this.$store.commit('setTraceId');
        this.$track({
          page_title: `分享落地页`,
          element_name: '进入页面',
          remarks: this.traceId,
        });
      },
      handleModelV2(flag) {
        this.welcomeModelV2Show = flag;
        if (flag) {
          this.$track({
            page_title: this.PageTitle,
            element_name: '选择弹窗曝光',
          });
        } else {
          this.$track({
            page_title: this.PageTitle,
            element_name: '选择弹窗曝光',
            remarks: '关闭',
          });
        }
      },
      /**
       * @description 关闭弹窗
       */

      closeModel() {
        this.$track({
          page_title: this.PageTitle,
          element_name: '弹窗曝光',
          remarks: '关闭',
        });
        if (this.MusicContentAb === 'c' || this.MusicContentAb === 'd') {
          this.modalOpenTime = this.modalOpenTime + 5000;
          // 第二次关闭后不再弹窗
          if (this.modalOpenTime > 20000) {
            document.removeEventListener('click', this.setTimeoutFun, false);
          }
        }
        clearTimeout(this.userHandleTimer);
        this.userHandleTimer = null;
        this.welcomeModelShow = false;
      },
      /**
       * @description: 初始化友盟 ULink SDK
       **/

      initULink() {
        let selectorStr =
          '#com-button-open-app,#modal-open-app,#btn-sing,#button-bot-open-app,#button-top-open-app,#more-content,#aviator-focus,#video_name,#more-like-warp,#video-more-content,#music-like,#music-dis,#music-more,#set-ring,#look-song,#auto-download,#focus-dom,#music-name,#popular_top,#download-btn-v3,#modal-open-app-v2';
        if (this.mediaInfo.is_download === 'a') {
          if (this.mediaInfo.hot_video_list.length > 0) {
            this.mediaInfo.hot_video_list.forEach((item, index) => {
              selectorStr = `${selectorStr},#hot-video-btn${index}`;
            });
          }
          if (this.mediaInfo.hot_music_list.length > 0) {
            this.mediaInfo.hot_music_list.forEach((item, index) => {
              selectorStr = `${selectorStr},#music-play-btn${index}`;
            });
          }
        }

        let linkId = 'usr13t43gs2mbp32';

        let umLink = new InitUmLink({
          selector: selectorStr,
          linkId,
          pageTitle: '内容分享落地页',
          clipboardText: this.mediaInfo.invite_code,
          traceId: this.traceId,
          ext: { id: this.$route.query.music_code || '' },
        });
        this.SET_UM_LINK(umLink);
        console.log(umLink);
      },
      /**
       * @description 下载APP
       * @param {string} element_name 节点名称
       * @param {string} element_id 节点id
       * @param {string} remarks remarks

       */

      openApp(element_name, element_id, remarks = '') {
        this.$track({
          page_title: this.PageTitle,
          element_name,
          remarks,
        });

        this.$track({
          page_title: `分享落地页`,
          element_name: '下载',
          remarks: this.traceId,
        });

        try {
          const clipboard = new Clipboard(element_id);

          let jump = () => {
            let url;

            if (this.$platform.Android) {
              url = Download.downLoad('yyb');
            } else if (this.$platform.iOS) {
              url = Download.downLoad('share_ios');
            } else {
              url = Download.downLoad('yyb');
            }
            window.location.href = url;
          };

          this.$toast({
            message: '亲~正在为你拉起下载页',
            duration: 5000,
            overlay: false,
          });
          // 使用友盟 U-link
          if (this.UmLink.isUmLinkInitSuccess) {
            return;
          }

          // 无邀请码时，无法 catch 到错误
          if (!this.mediaInfo.invite_code) {
            jump();
            return;
          }

          clipboard.on('success', (e) => {
            e.clearSelection();

            jump();
          });
          clipboard.on('error', () => {
            jump();
          });
        } catch (e) {
          const { stack } = e;
          this.$logger.error({ error: e, message: stack, stack });
        }
      },
      /**
       * @description @定时弹窗
       */

      setTimeoutFun(e) {
        if (this.MusicContentAb === 'c' || this.MusicContentAb === 'd') {
          if (this.modalOpenTime > 20000) {
            return;
          }
        }
        this.userHandleTimer && clearTimeout(this.userHandleTimer);

        // 弹窗关闭按钮不设置计时器
        if (e.target.id !== 'modal-img-close') {
          this.userHandleTimer = setTimeout(() => {
            if (!this.welcomeModelShow) {
              this.welcomeModelShow = true;
              this.$track({
                page_title: this.PageTitle,
                element_name: '弹窗曝光',
              });
            }
          }, this.modalOpenTime);
        }
      },
      /**
       * @description @播放音视频
       */

      playMusicOrVideo() {
        this.musicOrVideoShow ? this.changeAudioPlay(true) : this.changeVideoPlay(true);
      },
      /**
       * @description @改变视频状态
       * @param {Boolean} flag 视频状态
       * @return @void
       */

      changeVideoPlay(flag) {
        this.$emit('changeVideoPlay', flag);
      },
      /**
       * @description @改变音频状态
       * @param {Boolean} flag 音乐状态
       */

      changeAudioPlay(flag) {
        this.$emit('changeAudioPlay', flag);
      },

      /**
       * @description @观看推荐视频
       * @param {string} video_id 视频id
       * @param {string} share_from uid
       * @param {Object} share_info
       * @return @void
       */

      seeRecommendedVideo(video_id, share_from, share_info) {
        this.$track({
          page_title: this.PageTitle,
          element_name: '播放视频',
        });
        let um_from_appkey = Array.isArray(this.$route.query.um_from_appkey)
          ? this.$route.query.um_from_appkey[this.$route.query.um_from_appkey.length - 1]
          : this.$route.query.um_from_appkey;

        window.location.href = `${this.replaceParamVal(
          share_info.url,
          'share_from',
          share_from
        )}&um_from_appkey=${um_from_appkey}`;
      },
      /**
       * @description @听推荐音乐
       * @param {string} music_code 音乐code
       * @param {string} share_from uid
       * @param {Object} share_info
       * @return @void
       */
      listenRecommendedMusic(music_code, share_from, share_info) {
        this.$track({
          page_title: this.PageTitle,
          element_name: '播放音乐',
        });

        let um_from_appkey = Array.isArray(this.$route.query.um_from_appkey)
          ? this.$route.query.um_from_appkey[this.$route.query.um_from_appkey.length - 1]
          : this.$route.query.um_from_appkey;

        window.location.href = `${this.replaceParamVal(
          share_info.url,
          'share_from',
          share_from
        )}&um_from_appkey=${um_from_appkey}`;
      },
      /**
       * 修改url参数
       */
      replaceParamVal(oUrl, key, value) {
        let re = eval('/(' + key + '=)([^&]*)/gi');
        return oUrl.replace(re, key + '=' + value);
      },
    },
  };
</script>

<style lang="less" scoped>
  .share-landing-com-container {
    user-select: none;
    overflow: auto;
    // min-height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .bg_content {
    background: url('~@/assets/images/share-content/bg.png') no-repeat 100% 100%;
    padding-top: 1rem;
    background-size: 100% 100%;
    min-height: 100vh;
    width: 100%;
    box-sizing: border-box;
  }
  .share-landing-com-top-warp-bg {
    position: fixed;
    z-index: 1;
    background: #fff;
    top: 0;
    left: 0;
    right: 0;
    height: 1rem;
  }
  .share-landing-com-top-warp {
    display: flex;
    position: fixed;

    z-index: 91;
    background: rgba(255, 255, 255, 0.5);
    top: 0;
    left: 0;
    right: 0;
    height: 1rem;
    align-items: center;
    padding: 0 0.4rem;
    .top-warp-content {
      flex: 1;
      .top-warp-logo-name {
        font-size: 0.28rem;
        font-weight: bold;
      }
      .top-warp-invite-info {
        font-size: 0.2rem;
        font-weight: 400;
        color: #878787;
        .name {
          margin: 0 0.1rem;
          color: #ff2b3d;
        }
      }
    }
    .top-warp-img__logo {
      width: 0.72rem;
      height: 0.72rem;
      margin-right: 0.2rem;
    }
    .top-warp-open-btn {
      width: 1.4rem;
      height: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.5rem;
      font-size: 0.24rem;
      font-family: PingFangSC-Semibold, PingFang SC, sans-serif;
      font-weight: bold;
      color: #ffffff;
      line-height: 0.24rem;
      background: #ff2b3d;
    }
  }
</style>
