// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/share-content/bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".share-landing-com-container[data-v-175d4954]{-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;overflow:auto;position:absolute;top:0;left:0;right:0;bottom:0}.bg_content[data-v-175d4954]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 100% 100%;padding-top:1rem;background-size:100% 100%;min-height:100vh;width:100%;box-sizing:border-box}.share-landing-com-top-warp-bg[data-v-175d4954]{position:fixed;z-index:1;background:#fff;top:0;left:0;right:0;height:1rem}.share-landing-com-top-warp[data-v-175d4954]{display:flex;position:fixed;z-index:91;background:hsla(0,0%,100%,.5);top:0;left:0;right:0;height:1rem;align-items:center;padding:0 .4rem}.share-landing-com-top-warp .top-warp-content[data-v-175d4954]{flex:1}.share-landing-com-top-warp .top-warp-content .top-warp-logo-name[data-v-175d4954]{font-size:.28rem;font-weight:700}.share-landing-com-top-warp .top-warp-content .top-warp-invite-info[data-v-175d4954]{font-size:.2rem;font-weight:400;color:#878787}.share-landing-com-top-warp .top-warp-content .top-warp-invite-info .name[data-v-175d4954]{margin:0 .1rem;color:#ff2b3d}.share-landing-com-top-warp .top-warp-img__logo[data-v-175d4954]{width:.72rem;height:.72rem;margin-right:.2rem}.share-landing-com-top-warp .top-warp-open-btn[data-v-175d4954]{width:1.4rem;height:.5rem;display:flex;justify-content:center;align-items:center;border-radius:.5rem;font-size:.24rem;font-family:PingFangSC-Semibold,PingFang SC,sans-serif;font-weight:700;color:#fff;line-height:.24rem;background:#ff2b3d}", ""]);
// Exports
module.exports = exports;
